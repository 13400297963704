var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "nav nav-tabs flex-column" }, [
    _c(
      "div",
      {
        class:
          { bold: _vm.hasChildren } &&
          _vm.selectedGroupId === _vm.model.group_id
            ? "tab active mb-2"
            : "tab mb-2"
      },
      [
        _c("span", { on: { click: _vm.selectName } }, [
          _vm._v(_vm._s(_vm.model.name))
        ]),
        _vm.hasChildren
          ? _c(
              "span",
              { on: { click: _vm.toggle } },
              [
                _vm.isOpen
                  ? _c("b-icon", {
                      staticClass: "ml-1 top--3",
                      attrs: {
                        icon: "chevron-down",
                        "aria-hidden": "true",
                        scale: "1"
                      }
                    })
                  : _c("b-icon", {
                      staticClass: "ml-1 top--3",
                      staticStyle: { color: "#53DC86" },
                      attrs: {
                        icon: "chevron-right",
                        "aria-hidden": "true",
                        scale: "1"
                      }
                    })
              ],
              1
            )
          : _vm._e()
      ]
    ),
    _vm.hasChildren
      ? _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen,
                expression: "isOpen"
              }
            ],
            staticClass: "customIndent"
          },
          _vm._l(_vm.model.children, function(model) {
            return _c("TreeItem", {
              key: model.group_id,
              staticClass: "item",
              attrs: {
                model: model,
                selectedGroupId: _vm.selectedGroupId,
                selectedGroupPath: _vm.selectedGroupPath
              },
              on: { selected: _vm.selectGroup }
            })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }