<template>
  <!-- Tree view of all groups user has admin rights to -->
  <li class="nav nav-tabs flex-column">

    <!-- Name of group -->
    <div
        :class="{ bold: hasChildren } &&
        (selectedGroupId === model.group_id) ? 'tab active mb-2' : 'tab mb-2'"
    >
      <span @click="selectName">{{ model.name }}</span>
      <!-- If group has children -->
      <span v-if="hasChildren" @click="toggle">
        <b-icon
          v-if="isOpen"
          icon="chevron-down"
          class="ml-1 top--3"
          aria-hidden="true"
          scale="1"
        ></b-icon>
        <b-icon
          v-else
          icon="chevron-right"
          class="ml-1 top--3"
          aria-hidden="true"
          style="color: #53DC86"
          scale="1"
        ></b-icon>
      </span>
    </div>

    <!-- Custom recursive tree node indentation -->
    <ul class="customIndent" v-show="isOpen" v-if="hasChildren">
      <TreeItem
          class="item"
          v-for="model in model.children"
          v-bind:key="model.group_id"
          :model="model"
          :selectedGroupId="selectedGroupId"
          :selectedGroupPath="selectedGroupPath"
          @selected="selectGroup">
      </TreeItem>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'TreeItem',
  props: ['model', 'selectedGroupId', 'selectedGroupPath'],
  data() {
    return {
      // eslint-disable-next-line max-len
      isOpen: (this.selectedGroupPath.substr(0, this.model.group_path.length) === this.model.group_path) || (this.selectedGroupPath.split('.').includes(this.model.group_id)),
    };
  },
  computed: {
    hasChildren() {
      return this.model.children && this.model.children.length;
    },
  },
  methods: {
    toggle() {
      if (this.hasChildren) {
        this.isOpen = !this.isOpen;
      }
    },
    selectName() {
      if (this.hasChildren) {
        this.isOpen = true;
      }
      this.$emit('selected', this.model);
    },
    selectGroup(group) {
      console.log('here');
      const pathArray = group.group_path.split('.');
      console.log('pathArray', pathArray);
      console.log(group.group_id);
      if (pathArray.includes(this.model.group_id)) {
        this.isOpen = true;
      }
      this.$emit('selected', group);
    },
  },
};
</script>

<style scoped>
.customIndent {
  margin-left: -20px;
}
.tab.active {
  color: #fff !important;
}
.tab:hover {
  color: #53DC86;
}
</style>
